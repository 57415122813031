/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. Known issues: if used as inline value in the field argument, it will not be converted to a Date object. */
  DateTime: string;
  /** A representation of a Firmware version can be Android or Scaler */
  FirmwareVersion: string;
};

export type AgentReleaseChannel = {
  __typename?: 'AgentReleaseChannel';
  /** The newly desired release channel for the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<ReleaseChannel>;
  /** The last reported agent release channel of the displays. */
  reported: ReleaseChannel;
};

export type AllBookmarksState = {
  __typename?: 'AllBookmarksState';
  /** The newly desired bookmarks state. Cleared when successful, failed or timed out. */
  desired?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The last configured bookmarks. Beware that changes made through remote will not be reflected. */
  reported?: Maybe<Array<Scalars['String']>>;
};

/** The Android component of display firmware. */
export type Android = {
  __typename?: 'Android';
  /** Updates available to the Android component of the firmware. */
  availableUpdates: Array<Scalars['String']>;
  /** The latest job for android firmware update, includes planned job */
  latestJob?: Maybe<AndroidUpdateJob>;
  version: Scalars['FirmwareVersion'];
};

export type AndroidUpdateAborted = AndroidUpdateJob & {
  __typename?: 'AndroidUpdateAborted';
  /** The date at which the job has been aborted. */
  abortedAt: Scalars['DateTime'];
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
  /** The version of the Android component to which the job will update. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type AndroidUpdateCompleted = AndroidUpdateJob & {
  __typename?: 'AndroidUpdateCompleted';
  /** The date at which the job is completed. */
  completedAt: Scalars['DateTime'];
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
  /** The version of the Android component to which the job will update. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type AndroidUpdateDownloading = AndroidUpdateJob & {
  __typename?: 'AndroidUpdateDownloading';
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The percentage downloaded of the next Android version. */
  downloadProgress: Scalars['Float'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
  /** The version of the Android component to which the job will update. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type AndroidUpdateInstalling = AndroidUpdateJob & {
  __typename?: 'AndroidUpdateInstalling';
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
  /** The version of the Android component to which the job will update. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type AndroidUpdateJob = {
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
  /** The version of the Android component to which the job will update. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type AndroidUpdatePlanned = AndroidUpdateJob & {
  __typename?: 'AndroidUpdatePlanned';
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
  /** The version of the Android component to which the job will update. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type AndroidUpdateRejected = AndroidUpdateJob & {
  __typename?: 'AndroidUpdateRejected';
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
  /** The date at which the job is rejected. */
  rejectedAt: Scalars['DateTime'];
  /** The reason code for rejection. */
  rejectionCode: Scalars['String'];
  /** The version of the Android component to which the job will update. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  /** The alias of the API key. */
  alias: Scalars['String'];
  /** The timestamp at which the API key was created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the API key. */
  id: Scalars['ID'];
};

export type ApiKeyCreateInput = {
  /** The alias which will be given to the API key. */
  alias: Scalars['String'];
};

export type ApiKeyCreatePayload = {
  __typename?: 'ApiKeyCreatePayload';
  /** The metadata of the API key that was created. */
  apiKey: ApiKey;
  /** The API key which can be used to authenticate yourself programmatically. You should store this, as you won't be able to access it again. */
  apiKeyValue: Scalars['String'];
};

export type ApiKeyRevokeInput = {
  /** The identifier of the API key. */
  apiKeyId: Scalars['String'];
};

export type ApiKeyRevokePayload = {
  __typename?: 'ApiKeyRevokePayload';
  /** The ID of API key that was revoked. */
  apiKeyId: Scalars['ID'];
};

export type AppContentSource = {
  __typename?: 'AppContentSource';
  /** The activities of the app. */
  activityList?: Maybe<Array<Scalars['String']>>;
  /** The id of the application. */
  applicationId: Scalars['String'];
  /** Whether or not the app can be set as a boot on source. */
  isBootable?: Maybe<Scalars['Boolean']>;
  /** The name of the app. */
  label?: Maybe<Scalars['String']>;
  /** Selected Activity of the app. */
  selectedActivity?: Maybe<Scalars['String']>;
};

export type AppInstallation = {
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** The identifier of installation. */
  id: Scalars['ID'];
};

export type AppInstallationFailed = AppInstallation & {
  __typename?: 'AppInstallationFailed';
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** A message describing why the installation failed. */
  errorMessage: Scalars['String'];
  /** The identifier of installation. */
  id: Scalars['ID'];
};

export type AppInstallationInstallPending = AppInstallation & {
  __typename?: 'AppInstallationInstallPending';
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** The identifier of installation. */
  id: Scalars['ID'];
  /** The date at which the installation is planned. */
  plannedAt: Scalars['DateTime'];
};

export type AppInstallationInstalled = AppInstallation & {
  __typename?: 'AppInstallationInstalled';
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** The identifier of installation. */
  id: Scalars['ID'];
  /** The version code of the application. */
  versionCode: Scalars['String'];
  /** The version of the application. */
  versionName: Scalars['String'];
};

export type AppInstallationInstalling = AppInstallation & {
  __typename?: 'AppInstallationInstalling';
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** A percentage amount that indicates the download progress. */
  downloadProgress: Scalars['Float'];
  /** The identifier of installation. */
  id: Scalars['ID'];
  /** A flag that indicates is the app is currently being installed. */
  isInstalling: Scalars['Boolean'];
  /** The version code of the application. */
  versionCode: Scalars['String'];
  /** The version of the application. */
  versionName: Scalars['String'];
};

export type AppInstallationUninstallPending = AppInstallation & {
  __typename?: 'AppInstallationUninstallPending';
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** The identifier of installation. */
  id: Scalars['ID'];
  /** The date at which the installation is planned. */
  plannedAt: Scalars['DateTime'];
  /** The version code of the application. */
  versionCode: Scalars['String'];
  /** The version of the application. */
  versionName: Scalars['String'];
};

export type AppInstallationUninstalling = AppInstallation & {
  __typename?: 'AppInstallationUninstalling';
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** The identifier of installation. */
  id: Scalars['ID'];
  /** The version code of the application. */
  versionCode: Scalars['String'];
  /** The version of the application. */
  versionName: Scalars['String'];
};

export type AppInstallationUpdating = AppInstallation & {
  __typename?: 'AppInstallationUpdating';
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** The current version code of the application. */
  currentVersionCode: Scalars['String'];
  /** The current version of the application. */
  currentVersionName: Scalars['String'];
  /** A percentage amount that indicates the download progress. */
  downloadProgress: Scalars['Float'];
  /** The identifier of installation. */
  id: Scalars['ID'];
  /** A flag that indicates is the app is currently being installed. */
  isInstalling: Scalars['Boolean'];
  /** The next version code of the application. */
  nextVersionCode: Scalars['String'];
  /** The next version of the application. */
  nextVersionName: Scalars['String'];
};

export type AppUninstallationFailed = AppInstallation & {
  __typename?: 'AppUninstallationFailed';
  /** The identifier of the application. */
  applicationId: Scalars['ID'];
  /** A message describing why the installation failed. */
  errorMessage: Scalars['String'];
  /** The identifier of installation. */
  id: Scalars['ID'];
};

export type AutoRestart = {
  __typename?: 'AutoRestart';
  /** Enable or disable auto udpate feature */
  switch: Scalars['String'];
  /** Time at which device will do auto Reboot */
  time?: Maybe<Scalars['String']>;
};

export type AutoRestartState = {
  __typename?: 'AutoRestartState';
  /** The newly desired auto restart state of the display. with ON/OFF */
  desired?: Maybe<AutoRestart>;
  /** The last reported auto restart state of the display. */
  reported: AutoRestart;
};

export type BookmarkContentSource = {
  __typename?: 'BookmarkContentSource';
  /** The index of the bookmark. */
  index: Scalars['Int'];
};

export type Bookmarks = {
  __typename?: 'Bookmarks';
  all: AllBookmarksState;
};

export type BrightnessState = {
  __typename?: 'BrightnessState';
  /** The newly desired brightness level of the display. Ranging from 0 to 100. Cleared when successful, failed or timed out. */
  desired?: Maybe<Scalars['Int']>;
  /** The last reported brightness level of the display. */
  reported: Scalars['Int'];
};

export type ClaimableDisplay = {
  __typename?: 'ClaimableDisplay';
  /** The model of the display. */
  commercialTypeNumber: Scalars['String'];
  id: Scalars['ID'];
  /** The type of the platform. */
  platformType: Scalars['String'];
  /** The serial number of the display. */
  serialNumber: Scalars['String'];
};

export type ContentSource = AppContentSource | BookmarkContentSource | InputContentSource | PlaylistContentSource;

export type ContentSourceSettings = {
  __typename?: 'ContentSourceSettings';
  /** A list with available content sources. */
  available?: Maybe<Array<ContentSource>>;
  /** The current input source. */
  current?: Maybe<ContentSourceState>;
  /** The default input source of the display. */
  default?: Maybe<ContentSourceState>;
};

export type ContentSourceState = {
  __typename?: 'ContentSourceState';
  /** The newly desired input source of the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<ContentSource>;
  /** The last reported input source of the display. */
  reported: ContentSource;
};

export type ContentSourceStatistics = {
  __typename?: 'ContentSourceStatistics';
  disconnected: Scalars['Int'];
  sources: Array<SourceRecord>;
  standby: Scalars['Int'];
};

export enum ControlLockState {
  Locked = 'LOCKED',
  PowerOnly = 'POWER_ONLY',
  Unlocked = 'UNLOCKED',
  VolumeOnly = 'VOLUME_ONLY'
}

export type ControlState = {
  __typename?: 'ControlState';
  /** The newly desired state of the control for the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<ControlLockState>;
  /** The last reported state of the control for the display. */
  reported: ControlLockState;
  /** The supported values for the control state of the display. */
  supportedValues: Array<ControlLockState>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  /** A list of the current user's personal api keys */
  apiKeys: Array<ApiKey>;
  /** The email address of the current user. */
  email: Scalars['String'];
  /** The identifier of the current user. */
  id: Scalars['ID'];
};

export type Customer = {
  __typename?: 'Customer';
  /** The avatar's URL of the customer. */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Find a display associated to the customer. */
  display: Display;
  /** Amount of displays the customer has claimed. */
  displayCount: Scalars['Int'];
  /** Usage statistics for all displays associated with this customer. */
  displayUsage?: Maybe<UsageStatistics>;
  /** Displays associated with the customer. */
  displays: Array<Display>;
  /** Groups associated with this customer. */
  groups: Array<Group>;
  /** A human- and URL-friendly identifier of the customer. */
  handle: Scalars['String'];
  /** The identifier of the customer. */
  id: Scalars['ID'];
  /** The name of the customer. */
  name: Scalars['String'];
  /** The playlists associated with the customer. */
  playlists: Array<Playlist>;
  /** The power schedules associated with the customer. */
  powerSchedules: Array<PowerSchedule>;
  /** Sites associated with this customer. */
  sites: Array<Site>;
  /** All users associated with this customer. */
  users: Array<User>;
  /** Wave Subscription the customer is linked to. */
  waveSubscription?: Maybe<WaveSubscription>;
};


export type CustomerDisplayArgs = {
  id: Scalars['ID'];
};

export type CustomerCreateInput = {
  /** The avatar URL of the customer. */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** A human- and URL-friendly identifier of the customer. */
  handle: Scalars['String'];
  /** The name of the customer. */
  name: Scalars['String'];
  /** The subscription to link the customer to. */
  subscriptionId?: InputMaybe<Scalars['String']>;
  /** A list of user ids that are associated with this customer. */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomerDeleteInput = {
  /** The identifier of the customer. */
  customerId: Scalars['ID'];
};

export type CustomerDeletePayload = {
  __typename?: 'CustomerDeletePayload';
  /** The identifier of the customer that was deleted. */
  customerId: Scalars['ID'];
};

export type CustomerUpdateInput = {
  /** The avatar URL of the customer. */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** The identifier of the customer. */
  customerId: Scalars['ID'];
  /** A human- and URL-friendly identifier of the customer. */
  handle: Scalars['String'];
  /** The new name of the customer. */
  name: Scalars['String'];
  /** The subscription to link the customer to. */
  subscriptionId?: InputMaybe<Scalars['String']>;
  /** A list of userIds that are associated with this customer. With a Lite Wave subscription, all users will automatically be added to the customer. */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum Day {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DeviceSdk = {
  __typename?: 'DeviceSdk';
  /** The device sdk type. */
  sdk: Scalars['String'];
  /** The device sdk version. */
  version: Scalars['String'];
};

export type Display = {
  __typename?: 'Display';
  /** The agent release channel of the display. */
  agentReleaseChannel?: Maybe<AgentReleaseChannel>;
  /** The agent's version running on the display */
  agentVersion?: Maybe<Scalars['String']>;
  /** The alerts associated to the display. */
  alerts: Array<DisplayAlert>;
  /** The alias of the display. */
  alias?: Maybe<Scalars['String']>;
  /** Subscriptions that are available to the display. */
  appSubscriptions: Array<DisplayAppSubscription>;
  /** The autoRestart state of the display, enable or disable state. */
  autoRestart?: Maybe<AutoRestartState>;
  /** The bookmarks of the display. */
  bookmarks: Bookmarks;
  /** The brightness state of the display. Ranging from 0 to 100. */
  brightness?: Maybe<BrightnessState>;
  /** The model of the display. */
  commercialTypeNumber: Scalars['String'];
  /** The content source of the display */
  contentSource?: Maybe<ContentSourceSettings>;
  /** The customer to which this display belongs. */
  customer: Customer;
  /** sdk version and type of display */
  deviceSdk?: Maybe<DeviceSdk>;
  /** The firmware version of the display. */
  firmware?: Maybe<Firmware>;
  /** The groups associated to the display. */
  groups: Array<Group>;
  /** Indicates whether the display has an empty shadow state and thus cannot properly interacted with. */
  hasEmptyShadow: Scalars['Boolean'];
  /** Whether the display has sensitive data. This includes bookmarks, screenshots or a job history. */
  hasSensitiveData: Scalars['Boolean'];
  /** The identifier of the display. */
  id: Scalars['ID'];
  /** The infra-red control state of the display. */
  infraRedControl?: Maybe<ControlState>;
  /** The keyboard control state of the display. */
  keyboardControl?: Maybe<ControlState>;
  /** The color of the LED strip of the display. */
  ledStripColor?: Maybe<LedStripColorState>;
  /** The network information of the display. */
  networkInformation: DisplayNetworkInformation;
  /** The display's network interfaces. */
  networkInterfaces?: Maybe<Array<NetworkInterface>>;
  /** The orientation of the display. */
  orientation?: Maybe<OrientationState>;
  /** The platform of the display. */
  platform: DisplayPlatform;
  /** The playlist associated with the display. */
  playlist?: Maybe<DisplayPlaylist>;
  /** The ports control state of the display. */
  portsControl?: Maybe<PortsControlState>;
  /** The power of the display. */
  power?: Maybe<PowerState>;
  /** The power schedule for the display. */
  powerSchedule?: Maybe<DisplayPowerSchedule>;
  /**
   * The power settings of the display.
   * @deprecated Use recommendedSettings instead.
   */
  powerSettings?: Maybe<PowerSettingsState>;
  /** The presence of the display, whether it is connected or not. */
  presence?: Maybe<DisplayPresence>;
  /** The reboot state of the display. */
  reboot?: Maybe<RebootState>;
  /** The recommended settings of the display. */
  recommendedSettings?: Maybe<RecommendedSettingsState>;
  /** A screenshot of the display. */
  screenshot?: Maybe<Screenshot>;
  /** The serial number of the display. */
  serialNumber: Scalars['String'];
  /** The signal detection state of the display. */
  signalDetection?: Maybe<SignalDetectionState>;
  /** The site associated to the display. */
  site?: Maybe<Site>;
  /** The time zone of the display. */
  timeZone?: Maybe<TimeZoneState>;
  /** The volume state of the display */
  volume?: Maybe<Volume>;
};

export type DisplayAbortJobInput = {
  /** The identifier of the job. */
  jobId: Scalars['ID'];
};

export type DisplayAbortJobPayload = {
  __typename?: 'DisplayAbortJobPayload';
  /** The updated display */
  display: Display;
};

export type DisplayAddGroupInput = {
  /** The identifier of the display. */
  displayId: Scalars['ID'];
  /** The identifier of the group that will be associated to the display. */
  groupId: Scalars['ID'];
};

export type DisplayAlert = {
  __typename?: 'DisplayAlert';
  /** The timestamp at which the alert was created. */
  createdAt: Scalars['DateTime'];
  /** The handle of the customer. */
  customerHandle: Scalars['String'];
  /** The identifier of the display. */
  displayId: Scalars['String'];
  /** The identifier of the alert. */
  id: Scalars['ID'];
  /** The message of the alert. */
  message: Scalars['String'];
};

export type DisplayAlertBulkResolveInput = {
  /** The identifiers of the alerts. */
  displayAlertIds: Array<Scalars['ID']>;
};

export type DisplayAlertBulkResolvePayload = {
  __typename?: 'DisplayAlertBulkResolvePayload';
  /** The identifiers of the displays which were resolved. */
  displayAlertIds: Array<Scalars['ID']>;
};

export type DisplayAppSubscription = {
  __typename?: 'DisplayAppSubscription';
  /** The app install for this subscription. */
  appInstallation?: Maybe<AppInstallation>;
  /** The installable app versions for this subscription. */
  appVersions?: Maybe<Array<Scalars['String']>>;
  /** The date that this subscription was created. */
  createdAt: Scalars['DateTime'];
  /** The url of the app icon of this subscription */
  iconUrl?: Maybe<Scalars['String']>;
  /** The identifier of this subscription */
  id: Scalars['ID'];
  /** The name of this subscription. */
  name: Scalars['String'];
  /** The date that this subscription was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The current usage of the subscription. */
  usage: SubscriptionUsage;
};

export type DisplayBulkAddGroupsInput = {
  /** The identifiers of the displays. */
  displayIds: Array<Scalars['ID']>;
  /** The groups that will be associated to the displays. */
  groupIds: Array<Scalars['ID']>;
};

export type DisplayBulkAddGroupsPayload = {
  __typename?: 'DisplayBulkAddGroupsPayload';
  /** The displays which had the new groups associated to them. */
  displays: Array<Display>;
  /** The groups assigned to the displays. */
  groups: Array<Group>;
};

export type DisplayBulkApplyRecommendedPowerSettingsInput = {
  /** The display ids to update */
  displayIds: Array<Scalars['ID']>;
};

export type DisplayBulkApplyRecommendedPowerSettingsPayload = {
  __typename?: 'DisplayBulkApplyRecommendedPowerSettingsPayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkApplyRecommendedSettingsInput = {
  /** The display ids to update */
  displayIds: Array<Scalars['ID']>;
};

export type DisplayBulkApplyRecommendedSettingsPayload = {
  __typename?: 'DisplayBulkApplyRecommendedSettingsPayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkClearDataInput = {
  /** The displays to clear. */
  displayIds: Array<Scalars['ID']>;
};

export type DisplayBulkClearDataPayload = {
  __typename?: 'DisplayBulkClearDataPayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkRebootInput = {
  /** The displays to update. */
  displayIds: Array<Scalars['ID']>;
  /** The timestamp at which this update is planned. Starts immediately if absent. */
  plannedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DisplayBulkRebootPayload = {
  __typename?: 'DisplayBulkRebootPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkRemoveGroupsInput = {
  /** The identifiers of the displays. */
  displayIds: Array<Scalars['ID']>;
  /** The identifiers of the groups that will be removed from the displays. */
  groupIds: Array<Scalars['ID']>;
};

export type DisplayBulkRemoveGroupsPayload = {
  __typename?: 'DisplayBulkRemoveGroupsPayload';
  /** The displays which the associated groups removed. */
  displays: Array<Display>;
  /** The groups removed from the displays. */
  groups: Array<Group>;
};

export type DisplayBulkRequestAppInstallationInput = {
  /** The version of the app that should be installed */
  appVersion?: InputMaybe<Scalars['String']>;
  /** The display IDs for which to create an installation request. */
  displayIds: Array<Scalars['ID']>;
  /** The subscription ID for which to create an installation request. */
  subscriptionId: Scalars['ID'];
};

export type DisplayBulkRequestAppInstallationPayload = {
  __typename?: 'DisplayBulkRequestAppInstallationPayload';
  /** The displays that an app installation was requested for. */
  displays: Array<Display>;
};

export type DisplayBulkUpdateAgentReleaseChannelInput = {
  /** The displays to update. */
  displayIds: Array<Scalars['ID']>;
  /** The state of the keyboard controls. */
  releaseChannel: ReleaseChannel;
};

export type DisplayBulkUpdateAgentReleaseChannelPayload = {
  __typename?: 'DisplayBulkUpdateAgentReleaseChannelPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdateAndroidFirmwareInput = {
  /** The ids of displays to update. */
  displayIds: Array<Scalars['ID']>;
  /** The timestamp at which this update is planned. Starts immediately if absent. */
  plannedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version to which the Android component of the firmware will be updated. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type DisplayBulkUpdateAndroidFirmwarePayload = {
  __typename?: 'DisplayBulkUpdateAndroidFirmwarePayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkUpdateAppContentSourceInput = {
  /** The activity of the application. */
  activity?: InputMaybe<Scalars['String']>;
  /** The id of the application. */
  applicationId: Scalars['String'];
  /** The displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The label of the application. */
  label?: InputMaybe<Scalars['String']>;
};

export type DisplayBulkUpdateAutoRestartInput = {
  /** The display ids to update. */
  displayIds: Array<Scalars['ID']>;
  /** The enable/disable auto restart feature for all display. */
  switch: SwitchType;
  /** The time at which all the displays should auto reboot. */
  time?: InputMaybe<Scalars['String']>;
};

export type DisplayBulkUpdateAutoRestartPayload = {
  __typename?: 'DisplayBulkUpdateAutoRestartPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdateBookmarkContentSourceInput = {
  /** The displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The index of the bookmark. */
  index: Scalars['Int'];
};

export type DisplayBulkUpdateBookmarksAllInput = {
  /** The bookmarks configuration that will be given to the display. */
  bookmarks: Array<Scalars['String']>;
  /** The display ids to update */
  displayIds: Array<Scalars['ID']>;
};

export type DisplayBulkUpdateBookmarksAllPayload = {
  __typename?: 'DisplayBulkUpdateBookmarksAllPayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkUpdateBrightnessInput = {
  /** The brightness value that will be applied to the displays */
  brightness: Scalars['Int'];
  /** The display ids to update. */
  displayIds: Array<Scalars['ID']>;
};

export type DisplayBulkUpdateBrightnessPayload = {
  __typename?: 'DisplayBulkUpdateBrightnessPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdateContentSourcePayload = {
  __typename?: 'DisplayBulkUpdateContentSourcePayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkUpdateDefaultAppContentSourceInput = {
  /** The id of the application. */
  applicationId: Scalars['String'];
  /** The displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The label of the application. */
  label?: InputMaybe<Scalars['String']>;
};

export type DisplayBulkUpdateDefaultBookmarkContentSourceInput = {
  /** The displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The index of the bookmark. */
  index: Scalars['Int'];
};

export type DisplayBulkUpdateDefaultContentSourcePayload = {
  __typename?: 'DisplayBulkUpdateDefaultContentSourcePayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkUpdateDefaultInputContentSourceInput = {
  /** The displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The input source. */
  source: Scalars['String'];
};

export type DisplayBulkUpdateDefaultPlaylistContentSourceInput = {
  /** The displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The id of the playlist. */
  playlistId: Scalars['String'];
};

export type DisplayBulkUpdateInfraRedControlInput = {
  /** The state of the infra-red controls. */
  controlState: ControlLockState;
  /** The displays to update. */
  displayIds: Array<Scalars['ID']>;
};

export type DisplayBulkUpdateInfraRedControlPayload = {
  __typename?: 'DisplayBulkUpdateInfraRedControlPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdateInputContentSourceInput = {
  /** The displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The input source. */
  source: Scalars['String'];
};

export type DisplayBulkUpdateKeyboardControlInput = {
  /** The state of the keyboard controls. */
  controlState: ControlLockState;
  /** The displays to update. */
  displayIds: Array<Scalars['ID']>;
};

export type DisplayBulkUpdateKeyboardControlPayload = {
  __typename?: 'DisplayBulkUpdateKeyboardControlPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdateLedStripColorInput = {
  /** The display ids to update */
  displayIds: Array<Scalars['ID']>;
  /** The ledStripColor state that will be given to the display. */
  ledStripColor: LedStripColor;
};

export type DisplayBulkUpdateLedStripColorPayload = {
  __typename?: 'DisplayBulkUpdateLedStripColorPayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkUpdatePlaylistContentSourceInput = {
  /** The displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The id of the playlist. */
  playlistId: Scalars['String'];
};

export type DisplayBulkUpdatePlaylistInput = {
  /** The display identifier. */
  displayIds: Array<Scalars['ID']>;
  /** The playlist identifier, or null to delete the media. */
  playlistId?: InputMaybe<Scalars['ID']>;
};

export type DisplayBulkUpdatePlaylistPayload = {
  __typename?: 'DisplayBulkUpdatePlaylistPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdatePortsControlInput = {
  /** The displays to update. */
  displayIds: Array<Scalars['ID']>;
  /** The state of the ports. */
  portsControlState: PortsControlLockState;
};

export type DisplayBulkUpdatePortsControlPayload = {
  __typename?: 'DisplayBulkUpdatePortsControlPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdatePowerInput = {
  /** The display ids to update */
  displayIds: Array<Scalars['ID']>;
  /** The power state that will be given to the display. */
  power: Power;
};

export type DisplayBulkUpdatePowerPayload = {
  __typename?: 'DisplayBulkUpdatePowerPayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkUpdatePowerScheduleInput = {
  /** The displays identifiers. */
  displayIds: Array<Scalars['ID']>;
  /** The power schedule identifier. */
  powerScheduleId?: InputMaybe<Scalars['ID']>;
};

export type DisplayBulkUpdatePowerSchedulePayload = {
  __typename?: 'DisplayBulkUpdatePowerSchedulePayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdateSignalDetectionInput = {
  /** The display ids to update. */
  displayIds: Array<Scalars['ID']>;
  /** Whether the signal detection should be enabled or not. */
  enable: Scalars['Boolean'];
};

export type DisplayBulkUpdateSignalDetectionPayload = {
  __typename?: 'DisplayBulkUpdateSignalDetectionPayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayBulkUpdateSiteInput = {
  /** The identifiers of the displays. */
  displayIds: Array<Scalars['ID']>;
  /** The site that will be associated with the displays. */
  siteId?: InputMaybe<Scalars['ID']>;
};

export type DisplayBulkUpdateSitePayload = {
  __typename?: 'DisplayBulkUpdateSitePayload';
  /** The displays which had their site updated */
  displays: Array<Display>;
  /** The site assigned to the given displays */
  site?: Maybe<Site>;
};

export type DisplayBulkUpdateTimeZoneInput = {
  /** The identifiers of the displays to update */
  displayIds: Array<Scalars['ID']>;
  /** The canonical time zone that will be configured on the display. */
  timeZone: Scalars['String'];
};

export type DisplayBulkUpdateTimeZonePayload = {
  __typename?: 'DisplayBulkUpdateTimeZonePayload';
  /** The changed displays */
  displays: Array<Display>;
};

export type DisplayBulkUpdateVolumeInput = {
  /** The ids of the displays to update. */
  displayIds: Array<Scalars['ID']>;
  /** The volume level that will be applied to the display. */
  level?: InputMaybe<Scalars['Int']>;
  /** The new volume maximum limit. */
  max?: InputMaybe<Scalars['Int']>;
  /** The new volume minimum limit. */
  min?: InputMaybe<Scalars['Int']>;
  /** The mute state that will be given to the displays. */
  mute?: InputMaybe<Scalars['Boolean']>;
};

export type DisplayBulkUpdateVolumePayload = {
  __typename?: 'DisplayBulkUpdateVolumePayload';
  /** The changed displays. */
  displays: Array<Display>;
};

export type DisplayCaptureScreenshotInput = {
  /** The display to capture a screenshot */
  displayId: Scalars['ID'];
};

export type DisplayCapturescreenshotPayload = {
  __typename?: 'DisplayCapturescreenshotPayload';
  /** The display that was captured. */
  display: Display;
};

export type DisplayClaimInput = {
  /** The alias that will be given to the display. */
  alias?: InputMaybe<Scalars['String']>;
  /** The customer to which the display will be claimed. */
  customerId: Scalars['ID'];
  /** The display code acting as a proof of physical access. */
  displayCode: Scalars['ID'];
  /** The identifier of the display. */
  displayId: Scalars['ID'];
  /** The site that will be associated to the display. */
  siteId?: InputMaybe<Scalars['ID']>;
};

export type DisplayClaimPayload = {
  __typename?: 'DisplayClaimPayload';
  /** The customer that was associated to the display. */
  customer: Customer;
  /** The display that was created. */
  display: Display;
};

export type DisplayCurrentPlaylist = {
  __typename?: 'DisplayCurrentPlaylist';
  /** The description of the current playlist */
  description?: Maybe<Scalars['String']>;
  /** The identifier of the current playlist. */
  id: Scalars['ID'];
  /** A flag that indicates if the current playlist is out of sync with the actual playlist configuration. */
  isOutOfSync: Scalars['Boolean'];
  /** A flag that indicates if the current playlist has been modified on the display. */
  isTampered: Scalars['Boolean'];
  /** The total size of the current playlist. */
  size: Scalars['BigInt'];
  /** The title of the current playlist. */
  title: Scalars['String'];
};

export type DisplayDeleteGroupInput = {
  /** The identifier of the display. */
  displayId: Scalars['ID'];
  /** The group which will be removed from the display. */
  groupId: Scalars['ID'];
};

export type DisplayNetworkInformation = {
  __typename?: 'DisplayNetworkInformation';
  /** The MAC address of the ethernet adapter. */
  ethernetMacAddress: Scalars['String'];
  /** The local IP address of the display. */
  localIPAddress?: Maybe<Scalars['String']>;
  /** The last reported network type. Either "ETHERNET" or "WIFI". */
  networkType?: Maybe<NetworkType>;
  /** The MAC address of the Wi-Fi adapter. */
  wifiMacAddress?: Maybe<Scalars['String']>;
};

export type DisplayPlatform = {
  __typename?: 'DisplayPlatform';
  /** The name of the platform. */
  name: Scalars['String'];
  /** The platform type. */
  type: Scalars['String'];
  /** The version of the platform. */
  version?: Maybe<Scalars['String']>;
};

export type DisplayPlaylist = {
  __typename?: 'DisplayPlaylist';
  /** The current playlist as reported by the display. */
  current?: Maybe<DisplayCurrentPlaylist>;
  /** The playlist that is being synced to the display. */
  sync?: Maybe<DisplayPlaylistSync>;
};

export type DisplayPlaylistSync = {
  /** The description of the playlist that is being synced to the display. */
  description?: Maybe<Scalars['String']>;
  /** The identifier of the playlist that is being synced to the display. */
  id: Scalars['ID'];
  /** The internal identifier for the sync job. */
  jobId: Scalars['String'];
  /** The total size of the playlist that is being synced to the display. */
  size: Scalars['BigInt'];
  /** The title of the playlist that is being synced to the display. */
  title: Scalars['String'];
};

export type DisplayPowerSchedule = {
  __typename?: 'DisplayPowerSchedule';
  /** Whether the schedule is synchronized with the display. */
  isSynced?: Maybe<Scalars['Boolean']>;
  /** The latest job for power schedule update. */
  latestJob?: Maybe<PowerScheduleUpdateJob>;
  /** The power schedule reported from the display. */
  schedule?: Maybe<PowerSchedule>;
};

export type DisplayPresence = {
  __typename?: 'DisplayPresence';
  /** Whether the display is connected. */
  connected: Scalars['Boolean'];
  /** The timestamp on which the display was last connected. */
  timestamp: Scalars['DateTime'];
};

export type DisplayRequestAppInstallationInput = {
  /** The version of the app that should be installed. If not specified the latest available version will be selected. */
  appVersion?: InputMaybe<Scalars['String']>;
  /** The display ID for which to create an installation request. */
  displayId: Scalars['ID'];
  /** The subscription ID for which to create an installation request. */
  subscriptionId: Scalars['ID'];
};

export type DisplayRequestAppInstallationPayload = {
  __typename?: 'DisplayRequestAppInstallationPayload';
  /** The display that an app installation was requested for. */
  display: Display;
};

export type DisplayRequestAppUninstallationInput = {
  /** The display ID for which to create the uninstall request. */
  displayId: Scalars['ID'];
  /** The subscription ID for which to create the uninstall request. */
  subscriptionId: Scalars['ID'];
};

export type DisplayRequestAppUninstallationPayload = {
  __typename?: 'DisplayRequestAppUninstallationPayload';
  /** The display that an app uninstallation was requested for. */
  display: Display;
};

export type DisplayRetryRequestAppInstallationInput = {
  /** The display that the installation should be retried for. */
  displayId: Scalars['ID'];
  /** The subscription ID for which to retry the installation. */
  subscriptionId: Scalars['ID'];
};

export type DisplayRetryRequestAppInstallationPayload = {
  __typename?: 'DisplayRetryRequestAppInstallationPayload';
  /** The display that the app installation was retried for. */
  display: Display;
};

export type DisplayRetryRequestAppUninstallationInput = {
  /** The ID of the display for which to retry delete app installation. */
  displayId: Scalars['ID'];
  /** The ID of the subscription for which to retry delete app installation. */
  subscriptionId: Scalars['ID'];
};

export type DisplayRetryRequestAppUninstallationPayload = {
  __typename?: 'DisplayRetryRequestAppUninstallationPayload';
  /** The display that the deletion of app installation was retried for. */
  display: Display;
};

export enum DisplayType {
  Protv = 'PROTV',
  Signage = 'SIGNAGE'
}

export type DisplayUnclaimInput = {
  /** The identifier of the display. */
  displayId: Scalars['ID'];
};

export type DisplayUnclaimPayload = {
  __typename?: 'DisplayUnclaimPayload';
  /** The customer from which the display was unclaimed. */
  customer: Customer;
};

export type DisplayUpdateAliasInput = {
  /** The alias which will be given to the display. */
  alias?: InputMaybe<Scalars['ID']>;
  /** The identifier of the display. */
  displayId: Scalars['ID'];
};

export type DisplayUpdateAutoRestartInput = {
  /** The identifier of the display. */
  id: Scalars['ID'];
  /** To enable auto restart feature in display. */
  switch: SwitchType;
  /** The time at which display auto reboot. */
  time?: InputMaybe<Scalars['String']>;
};

export type DisplayUpdateAutoRestartPayload = {
  __typename?: 'DisplayUpdateAutoRestartPayload';
  /** The display that was changed. */
  display: Display;
};

export type DisplayUpdateBookmarksAllInput = {
  /** The bookmarks configuration that will be given to the display. */
  bookmarks: Array<Scalars['String']>;
  /** The identifier of the display. */
  id: Scalars['ID'];
};

export type DisplayUpdateBookmarksAllPayload = {
  __typename?: 'DisplayUpdateBookmarksAllPayload';
  /** The display that was changed. */
  display: Display;
};

export type DisplayUpdateOrientationInput = {
  /** The identifier of the display. */
  id: Scalars['ID'];
  /** The orientation state that will be given to the display. */
  orientation: Orientation;
};

export type DisplayUpdateOrientationPayload = {
  __typename?: 'DisplayUpdateOrientationPayload';
  /** The display that was changed. */
  display: Display;
};

export type DisplayUpdatePlaylistInput = {
  /** The display identifier. */
  displayId: Scalars['ID'];
  /** The playlist identifier, or null to delete the media. */
  playlistId?: InputMaybe<Scalars['ID']>;
};

export type DisplayUpdatePlaylistPayload = {
  __typename?: 'DisplayUpdatePlaylistPayload';
  /** The updated display. */
  display: Display;
};

export type DisplayUpdatePowerInput = {
  /** The identifier of the display. */
  id: Scalars['ID'];
  /** The power state that will be given to the display. */
  power: Power;
};

export type DisplayUpdatePowerPayload = {
  __typename?: 'DisplayUpdatePowerPayload';
  /** The display that was changed. */
  display: Display;
};

export type DisplayUpdateSiteInput = {
  /** The identifier of the display. */
  displayId: Scalars['ID'];
  /** The site that will be associated with the display. */
  siteId?: InputMaybe<Scalars['ID']>;
};

export type DisplayUpdateVolumeLevelInput = {
  /** The identifier of the display. */
  id: Scalars['ID'];
  /** The volume level that will be given to the display. */
  level: Scalars['Int'];
};

export type DisplayUpdateVolumeLevelPayload = {
  __typename?: 'DisplayUpdateVolumeLevelPayload';
  /** The display that was changed. */
  display: Display;
};

export type DisplayUpdateVolumeLimitMaxInput = {
  /** The identifier of the display. */
  id: Scalars['ID'];
  /** The new volume maximum limit */
  max: Scalars['Int'];
};

export type DisplayUpdateVolumeLimitMaxPayload = {
  __typename?: 'DisplayUpdateVolumeLimitMaxPayload';
  /** The display that was changed. */
  display: Display;
};

export type DisplayUpdateVolumeLimitMinInput = {
  /** The identifier of the display. */
  id: Scalars['ID'];
  /** The new volume minimum limit */
  min: Scalars['Int'];
};

export type DisplayUpdateVolumeLimitMinPayload = {
  __typename?: 'DisplayUpdateVolumeLimitMinPayload';
  /** The display that was changed. */
  display: Display;
};

export type DisplayUpdateVolumeMuteInput = {
  /** The identifier of the display. */
  id: Scalars['ID'];
  /** The mute state that will be given to the display. */
  mute: Scalars['Boolean'];
};

export type DisplayUpdateVolumeMutePayload = {
  __typename?: 'DisplayUpdateVolumeMutePayload';
  /** The display that was changed. */
  display: Display;
};

export type FileUploadRequestInput = {
  /** The file size of the file to be uploaded. */
  size: Scalars['Int'];
  /** The MIME type of the file to be uploaded. */
  type: Scalars['String'];
};

export type FileUploadRequestPayload = {
  __typename?: 'FileUploadRequestPayload';
  /** The URL to fetch the file. */
  assetUrl: Scalars['String'];
  /** The signed URL to upload the file. */
  uploadUrl: Scalars['String'];
};

export type Firmware = {
  __typename?: 'Firmware';
  /** The Android component of the firmware. */
  android: Android;
  /** The Scaler component of the firmware. */
  scaler?: Maybe<Scaler>;
};

export type FirmwareUpdateAndroidInput = {
  /** The identifier of the display. */
  displayId: Scalars['ID'];
  /** The timestamp at which this update is planned. Starts immediately if absent. */
  plannedAt?: InputMaybe<Scalars['DateTime']>;
  /** The version to which the Android component of the firmware will be updated. */
  targetVersion: Scalars['FirmwareVersion'];
};

export type FirmwareUpdateAndroidPayload = {
  __typename?: 'FirmwareUpdateAndroidPayload';
  /** The display that will be updated. */
  display: Display;
};

export type Group = {
  __typename?: 'Group';
  /** The identifier of the group. */
  id: Scalars['ID'];
  /** The name of the group. */
  name: Scalars['String'];
};

export type GroupCreateInput = {
  /** The customer to which to add the group. */
  customerId: Scalars['ID'];
  /** The name of the group. */
  name: Scalars['String'];
};

export type GroupCreatePayload = {
  __typename?: 'GroupCreatePayload';
  /** The customer associated with the group. */
  customer?: Maybe<Customer>;
  /** The group that was created. */
  group?: Maybe<Group>;
};

export type InputContentSource = {
  __typename?: 'InputContentSource';
  /** The display input source. */
  source: Scalars['String'];
};

export enum LedStripColor {
  Blue = 'BLUE',
  Cyan = 'CYAN',
  Green = 'GREEN',
  Magenta = 'MAGENTA',
  Red = 'RED',
  Unspecified = 'UNSPECIFIED',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export type LedStripColorState = {
  __typename?: 'LedStripColorState';
  /** The newly desired state of the color of the LED strip. Cleared when successful, failed or timed out. */
  desired?: Maybe<LedStripColor>;
  /** The last reported color of the LED strip of the display. */
  reported: LedStripColor;
};

export type Media = {
  __typename?: 'Media';
  /** The date when the media was created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the media. */
  id: Scalars['ID'];
  /** The size of the media, in Bytes. */
  size: Scalars['BigInt'];
  /** The title of the media. */
  title: Scalars['String'];
  /** The MIME type of the media. */
  type: Scalars['String'];
};

export type MediaCreateRequestInput = {
  /** The identifier of the customer. */
  customerId: Scalars['ID'];
  /** The size of the media file. */
  size: Scalars['BigInt'];
  /** The title of the media. */
  title: Scalars['String'];
  /** The type of the media file. */
  type: Scalars['String'];
};

export type MediaCreateRequestPayload = {
  __typename?: 'MediaCreateRequestPayload';
  /** The identifier of the media. */
  mediaId: Scalars['ID'];
  /** The url where to upload the media. */
  uploadUrl: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Creates an API key.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer
   */
  apiKeyCreate: ApiKeyCreatePayload;
  /**
   * Revokes an API key.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer
   */
  apiKeyRevoke: ApiKeyRevokePayload;
  /**
   * Creates a new customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  customerCreate: Customer;
  /**
   * Deletes a customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  customerDelete: CustomerDeletePayload;
  /**
   * Updates a customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  customerUpdate: Customer;
  /**
   * Aborts a job.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayAbortJob: DisplayAbortJobPayload;
  /**
   * Adds a group to a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayAddGroup: Display;
  /**
   * Resolves multiple alerts. The alerts will no longer be visible.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayAlertBulkResolve: DisplayAlertBulkResolvePayload;
  /**
   * Adds groups to a list of displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkAddGroups: DisplayBulkAddGroupsPayload;
  /**
   * Apply recommended power settings a list of displays
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator, Installer
   */
  displayBulkApplyRecommendedPowerSettings: DisplayBulkApplyRecommendedPowerSettingsPayload;
  /**
   * Apply recommended settings a list of displays
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator, Installer
   */
  displayBulkApplyRecommendedSettings: DisplayBulkApplyRecommendedSettingsPayload;
  /**
   * Clears the data of a list of displays. Data cleared includes bookmarks, playlists and screenshots.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkClearData: DisplayBulkClearDataPayload;
  /**
   * Reboot all the displays in a list.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkReboot: DisplayBulkRebootPayload;
  /**
   * Remove groups to a list of displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkRemoveGroups: DisplayBulkRemoveGroupsPayload;
  /**
   * Request an app installation / update for multiple displays
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkRequestAppInstallation: DisplayBulkRequestAppInstallationPayload;
  /**
   * Set the agent release channel for the displays
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdateAgentReleaseChannel: DisplayBulkUpdateAgentReleaseChannelPayload;
  /**
   * Updates the Android component of the firmware.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdateAndroidFirmware: DisplayBulkUpdateAndroidFirmwarePayload;
  /**
   * Changes the content source of a display between one of bookmarks, applications, playlists or display inputs.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateAppContentSource: DisplayBulkUpdateContentSourcePayload;
  /**
   * Changes the autoRestart of a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdateAutoRestart: DisplayBulkUpdateAutoRestartPayload;
  /**
   * Changes the content source of a display between one of bookmarks, applications, playlists or display inputs.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateBookmarkContentSource: DisplayBulkUpdateContentSourcePayload;
  /**
   * Change the bookmarks for a list of displays
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateBookmarksAll: DisplayBulkUpdateBookmarksAllPayload;
  /**
   * Change the brightness for a set of displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdateBrightness: DisplayBulkUpdateBrightnessPayload;
  /**
   * Sets the default content source to an app. This is the app that will be selected whenever the display turns on.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateDefaultAppContentSource: DisplayBulkUpdateDefaultContentSourcePayload;
  /**
   * Sets the default content source to a bookmark. This is the bookmark that will be selected whenever the display turns on.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateDefaultBookmarkContentSource: DisplayBulkUpdateDefaultContentSourcePayload;
  /**
   * Sets the default content source to an input. This is the input that will be selected whenever the display turns on.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateDefaultInputContentSource: DisplayBulkUpdateDefaultContentSourcePayload;
  /**
   * Sets the default content source to a playlist. This is the playlist that will be selected whenever the display turns on.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateDefaultPlaylistContentSource: DisplayBulkUpdateDefaultContentSourcePayload;
  /**
   * Set the control state for the infra-red controls of the displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdateInfraRedControl: DisplayBulkUpdateInfraRedControlPayload;
  /**
   * Changes the content source of a display between one of bookmarks, applications, playlists or display inputs.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateInputContentSource: DisplayBulkUpdateContentSourcePayload;
  /**
   * Set the control state for the keyboard controls of the displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdateKeyboardControl: DisplayBulkUpdateKeyboardControlPayload;
  /**
   * Changes the color of the LED strip of a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdateLedStripColor: DisplayBulkUpdateLedStripColorPayload;
  /**
   * Synchronizes a playlist for a set of displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdatePlaylist: DisplayBulkUpdatePlaylistPayload;
  /**
   * Changes the content source of a display between one of bookmarks, applications, playlists or display inputs.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdatePlaylistContentSource: DisplayBulkUpdateContentSourcePayload;
  /**
   * Set the control state for the ports controls of the displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdatePortsControl: DisplayBulkUpdatePortsControlPayload;
  /**
   * Change the power for a list of displays
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdatePower: DisplayBulkUpdatePowerPayload;
  /**
   * Synchronizes a power schedule for a set of displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdatePowerSchedule: DisplayBulkUpdatePowerSchedulePayload;
  /**
   * Set signal detection ON or OFF for a list of displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayBulkUpdateSignalDetection: DisplayBulkUpdateSignalDetectionPayload;
  /**
   * Updates the site for a list of displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator, Installer
   */
  displayBulkUpdateSite: DisplayBulkUpdateSitePayload;
  /**
   * Change the time zone for multiple displays
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator, Installer
   */
  displayBulkUpdateTimeZone: DisplayBulkUpdateTimeZonePayload;
  /**
   * Change the mute value and the volume level, min, and max for a set of displays.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayBulkUpdateVolume: DisplayBulkUpdateVolumePayload;
  /**
   * Capture the screenshot of the display
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayCaptureScreenshot: DisplayCapturescreenshotPayload;
  /**
   * Claims a display to a customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Installer
   */
  displayClaim: DisplayClaimPayload;
  /**
   * Deletes a group from a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayDeleteGroup: Display;
  /**
   * Request an app installation for the display
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayRequestAppInstallation: DisplayRequestAppInstallationPayload;
  /**
   * Request an app uninstallation from the display
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayRequestAppUninstallation: DisplayRequestAppUninstallationPayload;
  /**
   * Retry an app installation for the display
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayRetryRequestAppInstallation: DisplayRetryRequestAppInstallationPayload;
  /**
   * Retry to remove an app installation from the display
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayRetryRequestAppUninstallation: DisplayRetryRequestAppUninstallationPayload;
  /**
   * Unclaims a display from a customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Installer
   */
  displayUnclaim: DisplayUnclaimPayload;
  /**
   * Updates a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator, Installer
   */
  displayUpdateAlias: Display;
  /**
   * Changes the autoRestart of a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayUpdateAutoRestart: DisplayUpdateAutoRestartPayload;
  /**
   * Changes the bookmarks configurations on the display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayUpdateBookmarksAll: DisplayUpdateBookmarksAllPayload;
  /**
   * Changes the orientation of a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayUpdateOrientation: DisplayUpdateOrientationPayload;
  /**
   * Synchronizes a playlist with a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayUpdatePlaylist: DisplayUpdatePlaylistPayload;
  /**
   * Changes the power of a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayUpdatePower: DisplayUpdatePowerPayload;
  /**
   * Updates the site of a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator, Installer
   */
  displayUpdateSite: Display;
  /**
   * Changes the volume level of a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayUpdateVolumeLevel: DisplayUpdateVolumeLevelPayload;
  /**
   * Changes the maximum volume limit of the display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayUpdateVolumeLimitMax: DisplayUpdateVolumeLimitMaxPayload;
  /**
   * Changes the minimum volume limit of the display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  displayUpdateVolumeLimitMin: DisplayUpdateVolumeLimitMinPayload;
  /**
   * Changes the volume mute state of a display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator
   */
  displayUpdateVolumeMute: DisplayUpdateVolumeMutePayload;
  /**
   * Request to upload a file to TP Vision's cloud storage.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  fileUploadRequest: FileUploadRequestPayload;
  /**
   * Updates the Android component of the firmware.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  firmwareUpdateAndroid: FirmwareUpdateAndroidPayload;
  /**
   * Creates a new group within a customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  groupCreate: GroupCreatePayload;
  /**
   * Request to upload media to the TP Vision's cloud storage.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  mediaCreateRequest: MediaCreateRequestPayload;
  /**
   * Updates a organization.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  organizationUpdate: Organization;
  /**
   * Creates a new playlist for a customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  playlistCreate: PlaylistCreatePayload;
  /**
   * Removes all displays from a playlist.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  playlistRemoveDisplays: PlaylistRemoveDisplaysPayload;
  /**
   * Synchronizes all displays for a playlist.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  playlistSyncDisplays: PlaylistSyncDisplaysPayload;
  /**
   * Mutation to update a playlist.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  playlistUpdate: PlaylistUpdatePayload;
  /**
   * Deletes the playlists.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  playlistsDelete: PlaylistsDeletePayload;
  /**
   * Creates a new power schedule for a customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  powerScheduleCreate: PowerScheduleCreatePayload;
  /**
   * Removes all displays from a power schedule.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  powerScheduleRemoveDisplays: PowerScheduleRemoveDisplaysPayload;
  /**
   * Synchronizes all displays for a power schedule.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  powerScheduleSyncDisplays: PowerScheduleSyncDisplaysPayload;
  /**
   * Updates a power schedule.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  powerScheduleUpdate: PowerScheduleUpdatePayload;
  /**
   * Deletes the power schedules.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  powerSchedulesDelete: PowerSchedulesDeletePayload;
  /**
   * Creates a site.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  siteCreate: SiteCreatePayload;
  /**
   * Deletes a site.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  siteDelete: SiteDeletePayload;
  /**
   * Updates a site.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Operator
   */
  siteUpdate: Site;
  /**
   * Updates a subscription.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  updateSubscription: WaveSubscription;
  /**
   * Creates a new user for the organization of the current user.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  userCreate: User;
  /**
   * Deletes a user from the organization. They will also not be able to login anymore with their credentials.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  userDelete: UserDeletePayload;
  /**
   * Updates a user.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  userUpdate: UserUpdatePayload;
};


export type MutationApiKeyCreateArgs = {
  input: ApiKeyCreateInput;
};


export type MutationApiKeyRevokeArgs = {
  input: ApiKeyRevokeInput;
};


export type MutationCustomerCreateArgs = {
  input: CustomerCreateInput;
};


export type MutationCustomerDeleteArgs = {
  input: CustomerDeleteInput;
};


export type MutationCustomerUpdateArgs = {
  input: CustomerUpdateInput;
};


export type MutationDisplayAbortJobArgs = {
  input: DisplayAbortJobInput;
};


export type MutationDisplayAddGroupArgs = {
  input: DisplayAddGroupInput;
};


export type MutationDisplayAlertBulkResolveArgs = {
  input: DisplayAlertBulkResolveInput;
};


export type MutationDisplayBulkAddGroupsArgs = {
  input: DisplayBulkAddGroupsInput;
};


export type MutationDisplayBulkApplyRecommendedPowerSettingsArgs = {
  input: DisplayBulkApplyRecommendedPowerSettingsInput;
};


export type MutationDisplayBulkApplyRecommendedSettingsArgs = {
  input: DisplayBulkApplyRecommendedSettingsInput;
};


export type MutationDisplayBulkClearDataArgs = {
  input: DisplayBulkClearDataInput;
};


export type MutationDisplayBulkRebootArgs = {
  input: DisplayBulkRebootInput;
};


export type MutationDisplayBulkRemoveGroupsArgs = {
  input: DisplayBulkRemoveGroupsInput;
};


export type MutationDisplayBulkRequestAppInstallationArgs = {
  input: DisplayBulkRequestAppInstallationInput;
};


export type MutationDisplayBulkUpdateAgentReleaseChannelArgs = {
  input: DisplayBulkUpdateAgentReleaseChannelInput;
};


export type MutationDisplayBulkUpdateAndroidFirmwareArgs = {
  input: DisplayBulkUpdateAndroidFirmwareInput;
};


export type MutationDisplayBulkUpdateAppContentSourceArgs = {
  input: DisplayBulkUpdateAppContentSourceInput;
};


export type MutationDisplayBulkUpdateAutoRestartArgs = {
  input: DisplayBulkUpdateAutoRestartInput;
};


export type MutationDisplayBulkUpdateBookmarkContentSourceArgs = {
  input: DisplayBulkUpdateBookmarkContentSourceInput;
};


export type MutationDisplayBulkUpdateBookmarksAllArgs = {
  input: DisplayBulkUpdateBookmarksAllInput;
};


export type MutationDisplayBulkUpdateBrightnessArgs = {
  input: DisplayBulkUpdateBrightnessInput;
};


export type MutationDisplayBulkUpdateDefaultAppContentSourceArgs = {
  input: DisplayBulkUpdateDefaultAppContentSourceInput;
};


export type MutationDisplayBulkUpdateDefaultBookmarkContentSourceArgs = {
  input: DisplayBulkUpdateDefaultBookmarkContentSourceInput;
};


export type MutationDisplayBulkUpdateDefaultInputContentSourceArgs = {
  input: DisplayBulkUpdateDefaultInputContentSourceInput;
};


export type MutationDisplayBulkUpdateDefaultPlaylistContentSourceArgs = {
  input: DisplayBulkUpdateDefaultPlaylistContentSourceInput;
};


export type MutationDisplayBulkUpdateInfraRedControlArgs = {
  input: DisplayBulkUpdateInfraRedControlInput;
};


export type MutationDisplayBulkUpdateInputContentSourceArgs = {
  input: DisplayBulkUpdateInputContentSourceInput;
};


export type MutationDisplayBulkUpdateKeyboardControlArgs = {
  input: DisplayBulkUpdateKeyboardControlInput;
};


export type MutationDisplayBulkUpdateLedStripColorArgs = {
  input: DisplayBulkUpdateLedStripColorInput;
};


export type MutationDisplayBulkUpdatePlaylistArgs = {
  input: DisplayBulkUpdatePlaylistInput;
};


export type MutationDisplayBulkUpdatePlaylistContentSourceArgs = {
  input: DisplayBulkUpdatePlaylistContentSourceInput;
};


export type MutationDisplayBulkUpdatePortsControlArgs = {
  input: DisplayBulkUpdatePortsControlInput;
};


export type MutationDisplayBulkUpdatePowerArgs = {
  input: DisplayBulkUpdatePowerInput;
};


export type MutationDisplayBulkUpdatePowerScheduleArgs = {
  input: DisplayBulkUpdatePowerScheduleInput;
};


export type MutationDisplayBulkUpdateSignalDetectionArgs = {
  input: DisplayBulkUpdateSignalDetectionInput;
};


export type MutationDisplayBulkUpdateSiteArgs = {
  input: DisplayBulkUpdateSiteInput;
};


export type MutationDisplayBulkUpdateTimeZoneArgs = {
  input: DisplayBulkUpdateTimeZoneInput;
};


export type MutationDisplayBulkUpdateVolumeArgs = {
  input: DisplayBulkUpdateVolumeInput;
};


export type MutationDisplayCaptureScreenshotArgs = {
  input: DisplayCaptureScreenshotInput;
};


export type MutationDisplayClaimArgs = {
  input: DisplayClaimInput;
};


export type MutationDisplayDeleteGroupArgs = {
  input: DisplayDeleteGroupInput;
};


export type MutationDisplayRequestAppInstallationArgs = {
  input: DisplayRequestAppInstallationInput;
};


export type MutationDisplayRequestAppUninstallationArgs = {
  input: DisplayRequestAppUninstallationInput;
};


export type MutationDisplayRetryRequestAppInstallationArgs = {
  input: DisplayRetryRequestAppInstallationInput;
};


export type MutationDisplayRetryRequestAppUninstallationArgs = {
  input: DisplayRetryRequestAppUninstallationInput;
};


export type MutationDisplayUnclaimArgs = {
  input: DisplayUnclaimInput;
};


export type MutationDisplayUpdateAliasArgs = {
  input: DisplayUpdateAliasInput;
};


export type MutationDisplayUpdateAutoRestartArgs = {
  input: DisplayUpdateAutoRestartInput;
};


export type MutationDisplayUpdateBookmarksAllArgs = {
  input: DisplayUpdateBookmarksAllInput;
};


export type MutationDisplayUpdateOrientationArgs = {
  input: DisplayUpdateOrientationInput;
};


export type MutationDisplayUpdatePlaylistArgs = {
  input: DisplayUpdatePlaylistInput;
};


export type MutationDisplayUpdatePowerArgs = {
  input: DisplayUpdatePowerInput;
};


export type MutationDisplayUpdateSiteArgs = {
  input: DisplayUpdateSiteInput;
};


export type MutationDisplayUpdateVolumeLevelArgs = {
  input: DisplayUpdateVolumeLevelInput;
};


export type MutationDisplayUpdateVolumeLimitMaxArgs = {
  input: DisplayUpdateVolumeLimitMaxInput;
};


export type MutationDisplayUpdateVolumeLimitMinArgs = {
  input: DisplayUpdateVolumeLimitMinInput;
};


export type MutationDisplayUpdateVolumeMuteArgs = {
  input: DisplayUpdateVolumeMuteInput;
};


export type MutationFileUploadRequestArgs = {
  input: FileUploadRequestInput;
};


export type MutationFirmwareUpdateAndroidArgs = {
  input: FirmwareUpdateAndroidInput;
};


export type MutationGroupCreateArgs = {
  input: GroupCreateInput;
};


export type MutationMediaCreateRequestArgs = {
  input: MediaCreateRequestInput;
};


export type MutationOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};


export type MutationPlaylistCreateArgs = {
  input: PlaylistCreateInput;
};


export type MutationPlaylistRemoveDisplaysArgs = {
  input: PlaylistRemoveDisplaysInput;
};


export type MutationPlaylistSyncDisplaysArgs = {
  input: PlaylistSyncDisplaysInput;
};


export type MutationPlaylistUpdateArgs = {
  input: PlaylistUpdateInput;
};


export type MutationPlaylistsDeleteArgs = {
  input: PlaylistsDeleteInput;
};


export type MutationPowerScheduleCreateArgs = {
  input: PowerScheduleCreateInput;
};


export type MutationPowerScheduleRemoveDisplaysArgs = {
  input: PowerScheduleRemoveDisplaysInput;
};


export type MutationPowerScheduleSyncDisplaysArgs = {
  input: PowerScheduleSyncDisplaysInput;
};


export type MutationPowerScheduleUpdateArgs = {
  input: PowerScheduleUpdateInput;
};


export type MutationPowerSchedulesDeleteArgs = {
  input: PowerSchedulesDeleteInput;
};


export type MutationSiteCreateArgs = {
  input: SiteCreateInput;
};


export type MutationSiteDeleteArgs = {
  input: SiteDeleteInput;
};


export type MutationSiteUpdateArgs = {
  input: SiteUpdateInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: SubscriptionUpdateInput;
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  input: UserDeleteInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};

export type NetworkInterface = {
  __typename?: 'NetworkInterface';
  /** Indicates if it is the active network interface. */
  active: Scalars['Boolean'];
  /** The IP address of the network interface. */
  ip?: Maybe<Scalars['String']>;
  /** The MAC address of the network interface. */
  mac: Scalars['String'];
  /** The name of the network interface. */
  name: Scalars['String'];
  /** The SSID (name) of the Wi-Fi network when active. */
  ssid?: Maybe<Scalars['String']>;
};

export enum NetworkType {
  Ethernet = 'ETHERNET',
  Other = 'OTHER',
  Wifi = 'WIFI'
}

export type Organization = {
  __typename?: 'Organization';
  /** Subscriptions that are available to the organization. */
  appSubscriptions: Array<OrganizationAppSubscription>;
  /** The avatar's URL of the the organization. */
  avatarUrl?: Maybe<Scalars['String']>;
  /** All customers of this organization. */
  customers: Array<Customer>;
  /** If developer access is enabled for this organization. */
  developerAccess: Scalars['Boolean'];
  displayAlerts: Array<DisplayAlert>;
  /** A human- and URL-friendly identifier of the organization. */
  handle: Scalars['String'];
  /** The identifier of the organization. */
  id: Scalars['ID'];
  /** The name of the organization. */
  name: Scalars['String'];
  /** All users associated with this organization. */
  users: Array<User>;
  /** Wave Subscriptions that are available to the organization. */
  waveSubscriptions?: Maybe<Array<WaveSubscription>>;
};


export type OrganizationWaveSubscriptionsArgs = {
  isAssignable?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationAppSubscription = {
  __typename?: 'OrganizationAppSubscription';
  /** The available app versions for this subscription. */
  appVersions: Array<Scalars['String']>;
  /** The date that this subscription was created. */
  createdAt: Scalars['DateTime'];
  /** The displays with an installation of this subscription. */
  displays: Array<Display>;
  /** The url of the app icon of this subscription */
  iconUrl?: Maybe<Scalars['String']>;
  /** The identifier of this subscription */
  id: Scalars['ID'];
  /** The name of this subscription. */
  name: Scalars['String'];
  /** The date that this subscription was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The current usage of the subscription. */
  usage: SubscriptionUsage;
};

export type OrganizationUpdateInput = {
  /** The avatar URL of the organization. */
  avatarUrl?: InputMaybe<Scalars['String']>;
};

export enum Orientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT'
}

export type OrientationState = {
  __typename?: 'OrientationState';
  /** The newly desired orientation state of the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<Orientation>;
  /** The last reported orientation of the display. */
  reported: Orientation;
  /** The supported values for the orientation state of the display. */
  supportedValues: Array<Orientation>;
};

export type PartialCustomer = {
  __typename?: 'PartialCustomer';
  /** Amount of displays the customer has claimed. */
  displayCount: Scalars['Int'];
  /** Usage statistics for all displays associated with this customer. */
  displayUsage?: Maybe<UsageStatistics>;
  /** The identifier of the customer. */
  id: Scalars['ID'];
  /** The name of the customer. */
  name: Scalars['String'];
  /** Wave Subscription the customer is linked to. */
  waveSubscription?: Maybe<WaveSubscription>;
};

export type Playlist = {
  __typename?: 'Playlist';
  /** The date when the playlist was created. */
  createdAt: Scalars['DateTime'];
  /** The description of the playlist. */
  description?: Maybe<Scalars['String']>;
  /** The displays currently holding a reference to this playlist. */
  displays: Array<Display>;
  /** The identifier of the playlist. */
  id: Scalars['ID'];
  /** The list comprised only of uploaded media associated with the playlist. */
  media: Array<Media>;
  /** The total size of the playlist. */
  size: Scalars['BigInt'];
  /** The title of the playlist. */
  title: Scalars['String'];
};


export type PlaylistDisplaysArgs = {
  filter?: InputMaybe<PlaylistDisplayFilter>;
};

export type PlaylistContentSource = {
  __typename?: 'PlaylistContentSource';
  /** The id of the playlist. */
  playlistId: Scalars['ID'];
};

export type PlaylistCreateInput = {
  /** The ID of the customer. */
  customerId: Scalars['ID'];
  /** The description of the playlist. */
  description?: InputMaybe<Scalars['String']>;
  /** The media IDs of the media that will be part of the playlist. */
  mediaIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The playlist title. */
  title: Scalars['String'];
};

export type PlaylistCreatePayload = {
  __typename?: 'PlaylistCreatePayload';
  /** The playlist that was created.  */
  playlist: Playlist;
};

export type PlaylistDisplayFilter = {
  state?: InputMaybe<PlaylistDisplayState>;
};

export enum PlaylistDisplayState {
  OutOfSync = 'OUT_OF_SYNC',
  Removing = 'REMOVING',
  Synced = 'SYNCED',
  Syncing = 'SYNCING'
}

export type PlaylistRemoveDisplaysInput = {
  /** The identifier of the playlist. */
  playlistId: Scalars['ID'];
};

export type PlaylistRemoveDisplaysPayload = {
  __typename?: 'PlaylistRemoveDisplaysPayload';
  /** The removed displays. */
  displays: Array<Display>;
};

export type PlaylistSyncDisplaysInput = {
  /** The identifier of the playlist. */
  playlistId: Scalars['ID'];
};

export type PlaylistSyncDisplaysPayload = {
  __typename?: 'PlaylistSyncDisplaysPayload';
  /** The synced displays. */
  displays: Array<Display>;
};

export type PlaylistSyncFailed = DisplayPlaylistSync & {
  __typename?: 'PlaylistSyncFailed';
  /** The description of the playlist that is being synced to the display. */
  description?: Maybe<Scalars['String']>;
  /** A descriptive code that indicates why syncing has failed. */
  errorCode: Scalars['String'];
  /** The identifier of the playlist that is being synced to the display. */
  id: Scalars['ID'];
  /** The internal identifier for the sync job. */
  jobId: Scalars['String'];
  /** The total size of the playlist that is being synced to the display. */
  size: Scalars['BigInt'];
  /** The title of the playlist that is being synced to the display. */
  title: Scalars['String'];
};

export type PlaylistSyncing = DisplayPlaylistSync & {
  __typename?: 'PlaylistSyncing';
  /** The description of the playlist that is being synced to the display. */
  description?: Maybe<Scalars['String']>;
  /** The identifier of the playlist that is being synced to the display. */
  id: Scalars['ID'];
  /** A flag that indicates if the sync is planned and not currently in progress. */
  isPlanned: Scalars['Boolean'];
  /** A flag that indicates if the sync is removing the playlist. */
  isRemoving: Scalars['Boolean'];
  /** The internal identifier for the sync job. */
  jobId: Scalars['String'];
  /** A percentage amount that indicates the syncing progress. */
  progress: Scalars['Float'];
  /** The total size of the playlist that is being synced to the display. */
  size: Scalars['BigInt'];
  /** The title of the playlist that is being synced to the display. */
  title: Scalars['String'];
};

export type PlaylistUpdateInput = {
  /** The new description of the playlist. */
  description?: InputMaybe<Scalars['String']>;
  /** The new media of the playlist. */
  mediaIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The identifier of the playlist to update. */
  playlistId: Scalars['ID'];
  /** The new title of the playlist. */
  title?: InputMaybe<Scalars['String']>;
};

export type PlaylistUpdatePayload = {
  __typename?: 'PlaylistUpdatePayload';
  /** The playlist that was updated. */
  playlist: Playlist;
};

export type PlaylistsDeleteInput = {
  /** The ids of the playlists to delete. */
  playlistIds: Array<Scalars['ID']>;
};

export type PlaylistsDeletePayload = {
  __typename?: 'PlaylistsDeletePayload';
  /** The deleted playlists. */
  playlistIds: Array<Scalars['ID']>;
};

export enum PortsControlLockState {
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED'
}

export type PortsControlState = {
  __typename?: 'PortsControlState';
  /** The newly desired state of the ports control for the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<PortsControlLockState>;
  /** The last reported state of the ports control for the display. */
  reported: PortsControlLockState;
  /** The supported values for the control state of the display. */
  supportedValues: Array<PortsControlLockState>;
};

export enum Power {
  On = 'ON',
  Standby = 'STANDBY'
}

export type PowerSchedule = {
  __typename?: 'PowerSchedule';
  /** The date when the power schedule was created. */
  createdAt: Scalars['DateTime'];
  /** The description of the power schedule. */
  description?: Maybe<Scalars['String']>;
  /** The displays currently holding a reference to this power schedule. */
  displays: Array<Display>;
  /** The identifier of the power schedule. */
  id: Scalars['ID'];
  /** The time blocks associated with the power schedule. */
  timeBlocks: Array<TimeBlock>;
  /** The title of the power schedule. */
  title: Scalars['String'];
};


export type PowerScheduleDisplaysArgs = {
  filter?: InputMaybe<PowerScheduleDisplayFilter>;
};

export type PowerScheduleCreateInput = {
  /** The ID of the customer. */
  customerId: Scalars['ID'];
  /** The description of the powerSchedule. */
  description?: InputMaybe<Scalars['String']>;
  /** The powerSchedule title. */
  title: Scalars['String'];
};

export type PowerScheduleCreatePayload = {
  __typename?: 'PowerScheduleCreatePayload';
  /** The powerSchedule that was created.  */
  powerSchedule: PowerSchedule;
};

export type PowerScheduleDisplayFilter = {
  state?: InputMaybe<PowerScheduleDisplayState>;
};

export enum PowerScheduleDisplayState {
  OutOfSync = 'OUT_OF_SYNC',
  Removing = 'REMOVING',
  Synced = 'SYNCED',
  Syncing = 'SYNCING'
}

export type PowerScheduleRemoveDisplaysInput = {
  /** The identifier of the power schedule. */
  powerScheduleId: Scalars['ID'];
};

export type PowerScheduleRemoveDisplaysPayload = {
  __typename?: 'PowerScheduleRemoveDisplaysPayload';
  /** The removed displays. */
  displays: Array<Display>;
};

export type PowerScheduleSyncDisplaysInput = {
  /** The identifier of the power schedule. */
  powerScheduleId: Scalars['ID'];
};

export type PowerScheduleSyncDisplaysPayload = {
  __typename?: 'PowerScheduleSyncDisplaysPayload';
  /** The synced displays. */
  displays: Array<Display>;
};

export type PowerScheduleUpdateAborted = PowerScheduleUpdateJob & {
  __typename?: 'PowerScheduleUpdateAborted';
  /** The date at which the job has been aborted. */
  abortedAt: Scalars['DateTime'];
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The id of the schedule. */
  scheduleId?: Maybe<Scalars['ID']>;
};

export type PowerScheduleUpdateCompleted = PowerScheduleUpdateJob & {
  __typename?: 'PowerScheduleUpdateCompleted';
  /** The date at which the job is completed. */
  completedAt: Scalars['DateTime'];
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The id of the schedule. */
  scheduleId?: Maybe<Scalars['ID']>;
};

export type PowerScheduleUpdateInProgress = PowerScheduleUpdateJob & {
  __typename?: 'PowerScheduleUpdateInProgress';
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The id of the schedule. */
  scheduleId?: Maybe<Scalars['ID']>;
};

export type PowerScheduleUpdateInput = {
  /** The description of the power schedule. */
  description?: InputMaybe<Scalars['String']>;
  /** The identifier of the power schedule. */
  powerScheduleId: Scalars['ID'];
  /** The time blocks associated with the power schedule. */
  timeBlocks?: InputMaybe<Array<TimeBlockInput>>;
  /** The new title of the power schedule. */
  title?: InputMaybe<Scalars['String']>;
};

export type PowerScheduleUpdateJob = {
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The id of the schedule. */
  scheduleId?: Maybe<Scalars['ID']>;
};

export type PowerScheduleUpdatePayload = {
  __typename?: 'PowerScheduleUpdatePayload';
  /** The power schedule that was updated. */
  powerSchedule: PowerSchedule;
};

export type PowerScheduleUpdateRejected = PowerScheduleUpdateJob & {
  __typename?: 'PowerScheduleUpdateRejected';
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is rejected. */
  rejectedAt: Scalars['DateTime'];
  /** The reason code for rejection. */
  rejectionCode: Scalars['String'];
  /** The id of the schedule. */
  scheduleId?: Maybe<Scalars['ID']>;
};

export type PowerSchedulesDeleteInput = {
  /** The ids of the power schedules to delete. */
  powerScheduleIds: Array<Scalars['ID']>;
};

export type PowerSchedulesDeletePayload = {
  __typename?: 'PowerSchedulesDeletePayload';
  /** The deleted power schedules. */
  powerScheduleIds: Array<Scalars['ID']>;
};

export type PowerSettings = {
  __typename?: 'PowerSettings';
  /** Indicates whether the power settings are recommended by WAVE. */
  recommended: Scalars['Boolean'];
  /** Indicates whether the signal detection is supported and its state. */
  signalDetection?: Maybe<Scalars['Boolean']>;
  /** A list of warnings in case the settings are not recommended. */
  warnings: Array<SettingWarning>;
};

export type PowerSettingsState = {
  __typename?: 'PowerSettingsState';
  /** The newly desired power settings of the display. Cleared when successful, rejected or timed out. */
  desired?: Maybe<PowerSettings>;
  /** The last reported power settings of the display. */
  reported: PowerSettings;
};

export type PowerState = {
  __typename?: 'PowerState';
  /** The newly desired power state of the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<Power>;
  /** The last reported power of the display. */
  reported: Power;
  /** The date at which the power was last reported. */
  reportedAt: Scalars['DateTime'];
};

export type PowerStateStatistics = {
  __typename?: 'PowerStateStatistics';
  disconnected: Scalars['Int'];
  plannedOn: Scalars['Int'];
  plannedStandBy: Scalars['Int'];
  unplannedOn: Scalars['Int'];
  unplannedStandBy: Scalars['Int'];
};

export type PresenceStatistics = {
  __typename?: 'PresenceStatistics';
  connected: Scalars['Int'];
  disconnected: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * Retrieves one specific customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator, Guest, Installer
   */
  customer?: Maybe<Customer>;
  /**
   * Retrieves a customer by its handle
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator, Guest, Installer
   */
  customerByHandle?: Maybe<Customer>;
  /**
   * Retrieves one specific customer.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  customersByOrganization: QueryCustomersByOrganizationPayload;
  /**
   * A specific display.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator, Guest, Installer
   */
  display?: Maybe<Display>;
  /**
   * Partial data of a display, which is identified by a display code.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator, Guest, Installer
   */
  displayByCode?: Maybe<ClaimableDisplay>;
  /**
   * The currently logged in user
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator, Guest, Installer
   */
  me: CurrentUser;
  /**
   * The organization of the current user.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator, Guest, Installer
   */
  organization: Organization;
  /**
   * A specific playlist.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator, Guest, Installer
   */
  playlist?: Maybe<Playlist>;
  /**
   * A specific power schedule.
   * Requires any of the following roles: WaveAdmin, Owner, Admin, Developer, Operator, Guest, Installer
   */
  powerSchedule?: Maybe<PowerSchedule>;
  /**
   * A specific Wave Subscription.
   * Requires any of the following roles: WaveAdmin, Owner, Admin
   */
  waveSubscription?: Maybe<WaveSubscription>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerByHandleArgs = {
  handle: Scalars['String'];
};


export type QueryDisplayArgs = {
  id: Scalars['ID'];
};


export type QueryDisplayByCodeArgs = {
  displayCode: Scalars['String'];
};


export type QueryPlaylistArgs = {
  id: Scalars['ID'];
};


export type QueryPowerScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryWaveSubscriptionArgs = {
  id: Scalars['ID'];
};

export type QueryCustomersByOrganizationPayload = {
  __typename?: 'QueryCustomersByOrganizationPayload';
  /** The partial customers' data of the user's organization . */
  customers: Array<PartialCustomer>;
};

export type RebootJob = {
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
};

export type RebootJobAborted = RebootJob & {
  __typename?: 'RebootJobAborted';
  /** The date at which the job has been aborted. */
  abortedAt: Scalars['DateTime'];
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
};

export type RebootJobCompleted = RebootJob & {
  __typename?: 'RebootJobCompleted';
  /** The date at which the job is completed. */
  completedAt: Scalars['DateTime'];
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
};

export type RebootJobInProgress = RebootJob & {
  __typename?: 'RebootJobInProgress';
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
};

export type RebootJobPlanned = RebootJob & {
  __typename?: 'RebootJobPlanned';
  /** The date at which the job is created. */
  createdAt: Scalars['DateTime'];
  /** The identifier of the job. */
  id: Scalars['ID'];
  /** The date at which the job is planned. In case the job is invoked immediately, the value is equal to createdAt. */
  plannedAt: Scalars['DateTime'];
};

export type RebootState = {
  __typename?: 'RebootState';
  /** The last reboot job executed. */
  latestJob: RebootJob;
};

export type RecommendedSettings = {
  __typename?: 'RecommendedSettings';
  /** Indicates whether the settings are recommended by WAVE. */
  recommended: Scalars['Boolean'];
  /** A list of warnings in case the settings are not recommended. */
  warnings: Array<SettingWarning>;
};

export type RecommendedSettingsState = {
  __typename?: 'RecommendedSettingsState';
  /** The newly desired recommended settings of the display. Cleared when successful, rejected or timed out. */
  desired?: Maybe<RecommendedSettings>;
  /** The last reported recommended settings of the display. */
  reported: RecommendedSettings;
};

export enum ReleaseChannel {
  Alpha = 'ALPHA',
  Stable = 'STABLE'
}

export enum Role {
  Admin = 'Admin',
  Developer = 'Developer',
  Guest = 'Guest',
  Installer = 'Installer',
  Operator = 'Operator',
  Owner = 'Owner',
  WaveAdmin = 'WaveAdmin'
}

export type Scaler = {
  __typename?: 'Scaler';
  /** The version of the Scaler component of the firmware. */
  version: Scalars['FirmwareVersion'];
};

export type Screenshot = {
  /** The date at which the screenshot was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The URL of the screenshot. */
  url?: Maybe<Scalars['String']>;
};

export type ScreenshotEmpty = Screenshot & {
  __typename?: 'ScreenshotEmpty';
  /** The date at which the screenshot was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The URL of the screenshot. */
  url?: Maybe<Scalars['String']>;
};

export type ScreenshotPending = Screenshot & {
  __typename?: 'ScreenshotPending';
  /** The date at which the screenshot was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The URL of the screenshot. */
  url?: Maybe<Scalars['String']>;
};

export type ScreenshotRejected = Screenshot & {
  __typename?: 'ScreenshotRejected';
  /** The date at which the screenshot was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The code indicating the type of error that occurred. */
  errorCode: Scalars['String'];
  /** The date at which capturing the screenshot failed. */
  rejectedAt: Scalars['DateTime'];
  /** The URL of the screenshot. */
  url?: Maybe<Scalars['String']>;
};

export type ScreenshotResolved = Screenshot & {
  __typename?: 'ScreenshotResolved';
  /** The date at which the screenshot was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The URL of the screenshot. */
  url?: Maybe<Scalars['String']>;
};

export type SettingWarning = {
  __typename?: 'SettingWarning';
  /** A unique code identifying the warning. */
  code: Scalars['String'];
  /** The full description of the warning. */
  description: Scalars['String'];
  /** The severity of the warning. */
  severity: SettingWarningSeverity;
};

export enum SettingWarningSeverity {
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE'
}

export type SignalDetectionState = {
  __typename?: 'SignalDetectionState';
  /** The newly desired signal detection state of the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<Scalars['Boolean']>;
  /** The last reported signal detection state of the display. */
  reported: Scalars['Boolean'];
};

export type Site = {
  __typename?: 'Site';
  /** The address of the site. */
  address?: Maybe<Scalars['String']>;
  /** The identifier of the site. */
  id: Scalars['ID'];
  /** The name of the site. */
  name: Scalars['String'];
};

export type SiteCreateInput = {
  /** The address of the site. */
  address?: InputMaybe<Scalars['String']>;
  /** The customer to which to add the site. */
  customerId: Scalars['ID'];
  /** The name of the site. */
  name: Scalars['String'];
};

export type SiteCreatePayload = {
  __typename?: 'SiteCreatePayload';
  /** The customer associated with the site. */
  customer?: Maybe<Customer>;
  /** The site that was created. */
  site?: Maybe<Site>;
};

export type SiteDeleteInput = {
  /** The identifier of the site. */
  siteId: Scalars['ID'];
};

export type SiteDeletePayload = {
  __typename?: 'SiteDeletePayload';
  /** The site that was deleted. */
  siteId: Scalars['ID'];
};

export type SiteUpdateInput = {
  /** The address of the site. */
  address?: InputMaybe<Scalars['String']>;
  /** The name that will be given to the site. */
  name: Scalars['String'];
  /** The identifier of the site. */
  siteId: Scalars['ID'];
};

export type SourceRecord = {
  __typename?: 'SourceRecord';
  count: Scalars['Int'];
  source: Scalars['String'];
};

export type SubscriptionUpdateInput = {
  /** The IDs of the customers to assign to the subscription. */
  customerIds: Array<Scalars['ID']>;
  /** The ID of the subscription to update. */
  subscriptionId: Scalars['ID'];
};

export type SubscriptionUpdatePayload = {
  __typename?: 'SubscriptionUpdatePayload';
  /** The updated subscription. */
  subscription: WaveSubscription;
};

export type SubscriptionUsage = {
  __typename?: 'SubscriptionUsage';
  /** The amount of assignments used. */
  current: Scalars['Int'];
  /** The amount of available assignments. When `null`, there is no limit to the amount of available assignments */
  max?: Maybe<Scalars['Int']>;
};

export enum SwitchType {
  Off = 'OFF',
  On = 'ON'
}

export type TimeBlock = {
  __typename?: 'TimeBlock';
  /** The day of the week when the schedule has to be applied. */
  day: Day;
  /** The time of the day, with format hh:mm, when the display will go on standby.. */
  end: Scalars['String'];
  /** The time of the day, with format hh:mm, when the display will go online. */
  start: Scalars['String'];
};

export type TimeBlockInput = {
  day: Day;
  end: Scalars['String'];
  start: Scalars['String'];
};

export type TimeZoneState = {
  __typename?: 'TimeZoneState';
  /** The newly desired time zone, if any. */
  desired?: Maybe<Scalars['String']>;
  /** The last configured time zone. */
  reported: Scalars['String'];
  /** The supported values for the time zones of the display. */
  supportedValues: Array<Scalars['String']>;
};

export type UsageStatistics = {
  __typename?: 'UsageStatistics';
  contentSource: ContentSourceStatistics;
  powerState: PowerStateStatistics;
  presence: PresenceStatistics;
  warnings: WarningStatistics;
};

export type User = {
  __typename?: 'User';
  /** The customer ids the user has access to. */
  customerIds?: Maybe<Array<Scalars['String']>>;
  /** The email address of the user. */
  email: Scalars['String'];
  /** The last name of the user. */
  familyName: Scalars['String'];
  /** The first name of the user. */
  givenName: Scalars['String'];
  /** The identifier of the user. */
  id: Scalars['ID'];
  /** The roles of the user. */
  roles: Array<Role>;
};

export type UserCreateInput = {
  /** The customer ids the user has access to. */
  customerIds?: InputMaybe<Array<Scalars['String']>>;
  /** The email address of the new user. An invitation e-mail will be sent to this address. */
  email: Scalars['String'];
  /** The last name of the new user. */
  familyName?: InputMaybe<Scalars['String']>;
  /** The first name of the new user. */
  givenName?: InputMaybe<Scalars['String']>;
  /** Lite customers included or not to create new user. */
  liteSelected?: InputMaybe<Scalars['Boolean']>;
  /** The roles of the new user. */
  roles: Array<Role>;
};

export type UserDeleteInput = {
  /** The identifier of the user. */
  userId: Scalars['ID'];
};

export type UserDeletePayload = {
  __typename?: 'UserDeletePayload';
  /** The identifier of the user that was deleted. */
  userId: Scalars['String'];
};

export type UserUpdateInput = {
  /** The customer ids the user has access to. */
  customerIds?: InputMaybe<Array<Scalars['String']>>;
  /** The last name of the new user. */
  familyName: Scalars['String'];
  /** The first name of the new user. */
  givenName: Scalars['String'];
  /** Lite customers included or not to create new user. */
  liteSelected?: InputMaybe<Scalars['Boolean']>;
  /** The roles that will be given to the user. */
  roles: Array<Role>;
  /** The identifier of the user. */
  userId: Scalars['ID'];
};

export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  /** The user that was updated. */
  user: User;
};

export type Volume = {
  __typename?: 'Volume';
  /** Indicates whether the display is muted or not. */
  isMuted?: Maybe<VolumeMuteState>;
  /** The volume level of the display */
  level?: Maybe<VolumeLevelState>;
  /** The volume limits of the display */
  limits?: Maybe<VolumeLimitState>;
};

export type VolumeLevelState = {
  __typename?: 'VolumeLevelState';
  /** The newly desired volume level of the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<Scalars['Int']>;
  /** The last reported volume level of the display */
  reported: Scalars['Int'];
};

export type VolumeLimit = {
  __typename?: 'VolumeLimit';
  /** The newly desired volume limit of the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<Scalars['Int']>;
  /** The last reported volume limit of the display */
  reported: Scalars['Int'];
};

export type VolumeLimitState = {
  __typename?: 'VolumeLimitState';
  /** The maximum volume limit of the display, `null` if the display doesn't support a maximum limit. */
  max?: Maybe<VolumeLimit>;
  /** The minimum volume limit of the display, `null` if the display doesn't support a minimum limit. */
  min?: Maybe<VolumeLimit>;
};

export type VolumeMuteState = {
  __typename?: 'VolumeMuteState';
  /** The newly desired mute state of the display. Cleared when successful, failed or timed out. */
  desired?: Maybe<Scalars['Boolean']>;
  /** The last reported mute state of the display. */
  reported: Scalars['Boolean'];
};

export type WarningStatistics = {
  __typename?: 'WarningStatistics';
  emptyShadowWarnings: Scalars['Int'];
  outOfSyncPlaylistWarnings: Scalars['Int'];
  outOfSyncPowerScheduleWarnings: Scalars['Int'];
  playlistSyncFailedWarnings: Scalars['Int'];
  powerScheduleSyncFailedWarnings: Scalars['Int'];
  recommendedSettingsWarnings: Scalars['Int'];
  undefinedRecommendedSettingsWarnings: Scalars['Int'];
};

export type WaveSubscription = {
  __typename?: 'WaveSubscription';
  /** Indicates if the subscription is assignable to a customer. */
  assignable: Scalars['Boolean'];
  /** The date that this subscription was created. */
  createdAt: Scalars['DateTime'];
  /** The customers linked to the subscription. */
  customers: Array<PartialCustomer>;
  /** The identifier of this subscription */
  id: Scalars['ID'];
  /** The name of this subscription. */
  name: Scalars['String'];
  /** The product identifier in the AppDirect Marketplace. */
  productId: Scalars['String'];
  /** The date that this subscription was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The current usage of the subscription. */
  usage: SubscriptionUsage;
  /** The date that this subscription will expire. */
  validUntil: Scalars['DateTime'];
};
